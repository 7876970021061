import React from "react";

import { Link, graphql, useStaticQuery } from "gatsby";

export default function Navi() {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(
        frontmatter: { contentKey: { eq: "navi" }, name: { eq: "main" } }
      ) {
        frontmatter {
          items {
            name
            label
          }
        }
      }
    }
  `);

  return (
    <ul className="navbar-nav mr-auto d-flex align-items-center">
      {data.markdownRemark.frontmatter.items.map((item) => (
        <li className="nav-item" key={item.name}>
          <Link
            className="nav-link"
            to={item.name === "home" ? "/" : "/" + item.name}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}
