import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Navi from "./Navi";

export default function Footer() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <nav
      id="MagicMenu"
      className="topnav navbar navbar-expand-lg navbar-light bg-white fixed-top"
    >
      <div className="container">
        <Link className="navbar-brand" to="/">
          <strong>{data.site.siteMetadata.title}</strong>
        </Link>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarColor02"
          aria-controls="navbarColor02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div id="navbarColor02" className="navbar-collapse collapse">
          <Navi />
        </div>
      </div>
    </nav>
  );
}
