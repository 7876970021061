import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import gatsbyLogo from "../images/Gatsby_Logo.svg";
import netlifyLogo from "../images/Netlify_Logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { logo } from "./Footer.module.css";

export default function Footer() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          owner
        }
      }
    }
  `);

  return (
    <footer className="bg-white border-top p-3 text-muted small">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="navbar-brand mr-2 mb-0">
              <strong>{data.site.siteMetadata.title}</strong>
            </span>
            <span>
              © {new Date().getFullYear()} {data.site.siteMetadata.owner}
            </span>
          </div>
          <div>
            <span className={logo}>
              <img src={gatsbyLogo} alt="Gatsby Logo" height="30" />
            </span>
            <span className={logo}>
              <img src={netlifyLogo} alt="Netlify Logo" height="30" />
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
